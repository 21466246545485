<template>
  <div class="goods-det-box">
    <div class="info-box">
      <img @click="toReturn()" class="info-left-img" src="@/assets/seize-img/info-left.png" alt="">

      <img class="goods-img" :src="info.cover_img" alt="">
      <div class="box-card-f">
        <div class="box-card">
          <span class="c-red fs-12">￥</span><span class="c-red fs-22 m-r-16">{{info.price}}</span><span class="c-999 fs-12">原价¥{{info.original_price}}</span>
          <div class="fs-18 m-t-11 f-w-600 div-word">
            {{info.good_name}}
          </div>
        </div>

        <div class="box-card dis-flex dis-contern dis-space" @click="isPop = true">
          <div class="dis-flex dis-contern" >
            <div class="c-999 fs-14 m-r-16">规格</div>
            <div class="c-333 fs-14">选择规则</div>
          </div>

          <img src="@/assets/seize-img/z-right.png" alt="">
        </div>

        <div class="fs-16 c-333 box-card-title">—— 活动介绍 ——</div>
        <div class="box-card">
          <div class="" v-html="info.contents"></div>
        </div>
      </div>

      <div v-if="isPop" class="pop-sort">
        <div class="sort-nav">
          <div class="info-top">
            <div class="info-top-close fs-22 c-999" @click="isPop=false">×</div>

            <img :src="info.cover_img" alt="">
            <div class="info-top-text">
              <div class="">
                <span class="c-red fs-12">￥</span>
                <span class="c-red fs-22 m-r-16">{{spec_price}}</span>
                <span class="c-999 fs-12" style="text-decoration: line-through">￥{{info.original_price}}</span>
              </div>
              <div class="fs-18 m-t-11 f-w-600 div-word">
                {{info.good_name}}
              </div>
            </div>
          </div>
          <div class="info-spec">
            <div class="c-333 fs-18">规格：</div>
            <div class="info-spec-list">
              <div class="info-spec-list-box" :class="spec_id === item.id?'s-box':'n-box'"
              v-for="(item,i) in spec" :key="i" @click.stop="select(1,item)">
                {{item.spec_name}}
              </div>
            </div>
          </div>

          <div class="info-num">
            <div class="c-333 fs-18">购买数量</div>
            <div class="info-num-buttom">
              <div class="num-1 fs-22 c-999" @click.stop="select(2)">-</div>
              <input class="f-w-600 fs-16" type="number" v-model="num">
              <div class="num-2 fs-22 c-999" @click.stop="select(3)">+</div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="info-buttom phone-ios">
      <div class="buttom-1" @click="toUrl(1)">加入购物车</div>
      <div class="buttom-2" @click="toUrl(2)">立即购买</div>
    </div>
  </div>
</template>

<script>
  import { Toast  } from 'vant';

  import {robInfo,robSpec,robDdd,} from '@/api/rob.js';

  export default {
      data(){
        return{
          goods_id:'',
          info:{},
          isPop:false,
          spec:[],
          spec_id:'',
          num:1,
          isAJAX:false,
          spec_price:'',
        }
      },
      mounted() {
        if(this.$route.query.goods_id){
          this.goods_id = this.$route.query.goods_id
          this.address_id = this.$route.query.address_id
          if(this.goods_id){
            this.getInfo()
            this.getSpec()
          }
        }
      },
      methods:{
        toReturn(){
          this.$router.push({path:"/seize-goods"})
        },
        async getSpec(){
          await robSpec({goods_id:this.goods_id}).then((res)=>{
            if(res.code === 1){
              this.spec = Object.values(res.data.rows)
              this.spec_id = this.spec[0].id
              this.spec_price = this.spec[0].spec_price
            }
          })
        },
        async getInfo(){
          await robInfo({goods_id:this.goods_id}).then((res)=>{
            if(res.code === 1){
              this.info = res.data
            }
          })
        },

        select(type,item){
          if(type === 1){
            if(this.spec_id != item.id){
              this.spec_id = item.id
              this.spec_price = item.spec_price
            }
          }else if(type === 2){
            if(this.num > 1){
              this.num -= 1
            }
          }else if(type === 3){
            this.num += 1
          }
        },

        toUrl(type){
          if(this.isPop){
            if(type===1){
              //加入购物车
              if( this.num < 1){
                Toast.fail('请输入正确的数量')
                return
              }
              if(this.isAJAX){
                return
              }
              this.isAJAX = false
              robDdd({spec_id:this.spec_id,type:90,number:this.num}).then((res)=>{
                if(res.code === 1){
                  Toast.success(res.msg);
                  this.isAJAX = false;
                  this.isPop = false;
                  this.spec_id = this.spec[0].id;
                  this.num = 1;
                }else{
                  Toast.fail(res.msg);
                  this.isAJAX = false
                }
              })
            }else{
              // 立即购买
              if(this.isAJAX){
                return
              }
              this.isAJAX = false
              robDdd({spec_id:this.spec_id,type:90,number:this.num}).then((res)=>{
                if(res.code === 1){
                  this.isAJAX = false;
                  this.isPop = false;
                  this.spec_id = this.spec[0].id;
                  this.num = 1;
              
                  this.$router.push({
                    name: "create-order",
                    query: { shoppingIds: res.data.shopping_id,orderType:90},
                  });
                }else{
                  Toast.fail(res.msg);
                  this.isAJAX = false
                }
              })
            }
          }else{
            this.isPop = true
          }
        },
      },

  }
</script>

<style scoped lang="less">
  .goods-det-box{
    width: 100%;
    height: 100vh;
    background-color: #F5F4F9;
    display: flex;
    flex-direction: column;
    .box-card-title{
      text-align: center;
      margin: 44px 0 10px 0;
    }
    .div-word{
      word-break: break-all;
    }
    .f-w-600{
      font-weight: 600;
    }
    .m-r-16{
      margin-right: 16px;
    }
    .m-t-11{
      margin-top: 11px;
    }
    .fs-16{
      font-size: 16px;
    }
    .fs-22{
      font-size: 22px;
    }
    .fs-18{
      font-size: 18px;
    }
    .fs-14{
      font-size: 14px;
    }
    .fs-12{
      font-size: 12px;
    }
    .c-999{
      color: #999;
    }
    .c-333{
      color: #333;
    }
    .c-red{
      color: #ED301D;
    }
    .phone-ios{
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
    }
    .dis-flex{
      display: flex;
    }
    .dis-space{
      justify-content: space-between;
    }
    .dis-contern{
      align-items: center;
    }
    .info-box{
      width: 100%;
      flex: 1;
      overflow-y: scroll;
      position: relative;

      .pop-sort{
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        .sort-nav{
          position: absolute;
          bottom: 0;
          background-color: #fff;
          width: 100%;
          height: 60%;
          overflow: scroll;
          border-radius: 20px 20px 0 0;
          box-sizing: border-box;
          padding: 12px;
          .info-num{
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .info-num-buttom{
              display: flex;
              div{
                width: 50px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background-color: #f4f4f4;
              }
              input{
                width: 50px;
                height: 40px;
                border: none;
                text-align: center;
              }
              .num-1{
                border-radius: 20px 0 0 20px;
              }
              .num-2{
                border-radius: 0 20px 20px 0;
              }
            }
          }
          .info-spec{
            margin-top: 20px;
            .info-spec-list{
              display: flex;
              flex-flow: row wrap;
              margin-top: 10px;
              .info-spec-list-box{
                width: 60px;
                height: 28px;
                line-height: 30px;
                font-size: 14px;
                text-align: center;
                border-radius: 20px;
                margin-right: 10px;
                margin-bottom: 10px;
              }
              .s-box{
                border: 1px solid #ECA197;
                color: #ECA197;
                background-color: #FFF4F4;
              }
              .n-box{
                border: 1px solid #F2F2F2;
                color: #333333;
                background-color: #F2F2F2;
              }
            }
          }
          .info-top{
            display: flex;
            position: relative;
            .info-top-close{
              position: absolute;
              right: 0;
              top: 0;
            }
            img{
              width: 100px;
              height: 100px;
              border-radius: 10px;
              margin-right: 12px;
            }
            .info-top-text{
              flex: 1;
            }
          }
        }
      }


      .info-left-img{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 6px;
        left: 12px;
      }
      .goods-img{
        width: 100%;
        height: 375px;
        margin-bottom: 18px;
      }
      .box-card-f{
        width: 100%;
        box-sizing: border-box;
        padding: 0 12px;
        .box-card{
          margin-bottom: 10px;
          width: 100%;
          min-height: 54px;
          background: #FFFFFF;
          box-sizing: border-box;
          padding: 16px 12px;
          border-radius: 10px;
          img{
            width: 24px;
            height: 24px;
          }
        }
      }

    }
    .info-buttom{
      box-sizing: border-box;
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;
      height: 49px;
      background: #FFFFFF;
      display: flex;
      justify-content: center;
      div{
        width: 175px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        text-align: center;
        margin-top: 5px;
      }
      .buttom-1{
        background: linear-gradient(461deg, #FAC910 0%, #FF402D 100%);
        border-radius: 20px 0 0 20px;
      }
      .buttom-2{
        background: #ED301D;
        border-radius: 0 20px 20px 0;
      }
    }
  }
</style>
